<template>
  <div>
    <div class="styleHeader">工作量</div>
    <!-- 个人信息 -->
    <div class="styleInfo">
      <div class="flx">
        <div>科队：</div>
        <div>{{ reactiveParams.itemList.nameText }}</div>
      </div>
      <div class="flx">
        <div>班组：</div>
        <div>{{ reactiveParams.itemList.groupName }}</div>
      </div>
      <div class="flx">
        <div>工作日期：</div>
        <div>{{ reactiveParams.itemList.workdate }}</div>
      </div>
      <div class="flx">
        <div>班次：</div>
        <div>{{ reactiveParams.itemList.flight }}</div>
      </div>
    </div>
    <!-- 工作量左 -->
    <div
      class="styleInfo"
      v-for="(item, index) in reactiveParams.dataList"
      :key="index"
    >
      <div class="flx top">
        <div>工作量单价</div>
        <div v-if="reactiveParams.status">{{ item.workprice }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.workprice" />
        </div>
      </div>
      <div class="flx top">
        <div>收购工作量</div>
        <div v-if="reactiveParams.status">{{ item.workload }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.workload" />
        </div>
      </div>
      <div class="flx top">
        <div>安全管理</div>
        <div v-if="reactiveParams.status">{{ item.security }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.security" />
        </div>
      </div>
      <div class="flx top">
        <div>工程质量</div>
        <div v-if="reactiveParams.status">{{ item.quality }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.quality" />
        </div>
      </div>
      <div class="flx top">
        <div>现场文明</div>
        <div v-if="reactiveParams.status">{{ item.civilization }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.civilization" />
        </div>
      </div>
      <div class="flx top">
        <div>质量标椎</div>
        <div v-if="reactiveParams.status">{{ item.standard }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.standard" />
        </div>
      </div>
      <div class="flx top">
        <div>工具考核</div>
        <div v-if="reactiveParams.status">{{ item.tool }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.tool" />
        </div>
      </div>
      <div class="flx top">
        <div>其他考核</div>
        <div v-if="reactiveParams.status">{{ item.other }}</div>
        <div v-else>
          <van-stepper v-model="reactiveParams.other" />
        </div>
      </div>

      <!-- 为reactiveParams.status的false显示 -->
      <div v-if="reactiveParams.status">
        <div class="flx top">
          <div>节超合计</div>
          <div>{{ item.materialsaving }}</div>
        </div>
        <div class="flx top">
          <div>应得收入</div>
          <div>{{ item.netincome }}</div>
        </div>
      </div>

      <div class="flx7" style="margin-top: 0.2rem">
        <van-button
          type="primary"
          size="small"
          @click="reactiveParams.status ? compile() : affirm(item)"
        >
          {{ reactiveParams.status ? "编辑" : "确定" }}
        </van-button>
      </div>
    </div>
    <!-- 工作量右 -->
    <div class="styleHeader" style="margin-top: 0.3rem">材料</div>
    <div>
      <van-pull-refresh
        v-model="reactiveParams.refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model:loading="reactiveParams.loading"
          :finished="reactiveParams.finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="styleInfo"
            style="padding: 0.3rem"
            v-for="(item, index) in reactiveParams.materialslist"
            :key="index"
          >
            <div class="flx" style="margin-top: 0.2rem">
              <div>材料名称</div>
              <div>{{ item.materialName }}</div>
            </div>
            <div class="flx" style="margin-top: 0.2rem">
              <div>材料单价</div>
              <div>{{ item.price }}</div>
            </div>
            <div class="flx" style="margin-top: 0.2rem">
              <div>计划消耗</div>
              <div>{{ item.planuse }}</div>
            </div>
            <div class="flx" style="margin-top: 0.2rem">
              <div>实际消耗</div>
              <div>{{ item.practicause }}</div>
            </div>
            <div class="flx" style="margin-top: 0.2rem">
              <div>节超数</div>
              <div>{{ item.planuse - item.practicause }}</div>
            </div>
            <div class="flx" style="margin-top: 0.2rem">
              <div>小计</div>
              <div>{{ item.price * (item.planuse + -item.practicause) }}</div>
            </div>
            <div class="flx" style="margin-top: 0.3rem">
              <van-button
                type="primary"
                size="small"
                @click="EditMaterial(item)"
              >
                编辑
              </van-button>
              <van-button type="danger" size="small" @click="deletesone(item)">
                删除
              </van-button>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 材料添加 -->
    <div>
      <Add @addRoute="addRoute" />
    </div>
    <!-- props -->
    <van-popup
      v-model:show="MaterialShow"
      :style="{ height: '60%', width: '70%', 'border-radius': '0.2rem' }"
      closeable
    >
      <div style="margin-top: 0.4rem; padding: 0.2rem">
        <van-field
          v-model="reactiveParams.result"
          is-link
          readonly
          required
          name="picker"
          label="材料名称"
          placeholder="请选择材料"
          @click="reactiveParams.showPicker = true"
        />
        <!-- 材料修改 -->
        <!--  -->
        <van-field name="stepper" label="材料单价" input-align="right">
          <template #input>
            <van-stepper
              v-model="materialsAdd.UnitPriceMaterials"
              min="-10000000000000000000000000000000000000"
              @change="unitPrice"
            />
          </template>
        </van-field>
        <!--  -->
        <van-field name="stepper" label="计划消耗" input-align="right">
          <template #input>
            <van-stepper
              v-model="materialsAdd.PlannedConsumption"
              min="-10000000000000000000000000000000000000"
              @change="Planned"
            />
          </template>
        </van-field>
        <!--  -->
        <van-field name="stepper" label="实际消耗" input-align="right">
          <template #input>
            <van-stepper
              v-model="materialsAdd.actualConsumption"
              min="-10000000000000000000000000000000000000"
              @change="actual"
            />
          </template>
        </van-field>
        <!--  -->
        <van-field name="stepper" label="节超数" input-align="right">
          <template #input>
            <van-stepper
              v-model="materialsAdd.NodalExcess"
              :disabled="true"
              min="-10000000000000000000000000000000000000"
            />
          </template>
        </van-field>
        <!--  -->
        <van-field name="stepper" label="小计" input-align="right">
          <template #input>
            <van-stepper
              v-model="materialsAdd.subtotal"
              :disabled="true"
              input-width="1rem"
              min="-10000000000000000000000000000000000000"
            />
          </template>
        </van-field>
        <!-- 提交 -->
        <div style="width: 90%; margin: 0 auto; margin-top: 0.2rem">
          <van-button
            type="primary"
            size="large"
            style="border-radius: 0.2rem"
            @click="materialsulit"
          >
            提交
          </van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="reactiveParams.showPicker" position="bottom">
      <van-picker
        :columns="reactiveParams.materialsLists"
        @confirm="onConfirm"
        @cancel="reactiveParams.showPicker = false"
        :columns-field-names="reactiveParams.columnsFieldNames"
      />
    </van-popup>
  </div>
</template>
<script>
  import {
    ref,
    inject,
    reactive,
    toRefs,
    provide,
    getCurrentInstance,
    onMounted,
  } from "vue";
  import Add from "@/components/componentAdd/index";
  import { useRoute, useRouter } from "vue-router";
  import { Toast } from "vant";
  export default {
    components: {
      Add,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const reactiveParams = reactive({
        propstaus: false,
        time: "",
        result: "",
        showPicker: false,
        columns: [],
        MaterialShow: false,
        itemList: "",
        status: true,
        materialsLists: [],
        columnsFieldNames: { text: "name" },
        left: {
          UnitWork: "",
          AcquisitionWorkload: "",
          safetyManagement: "",
          projectQuality: "",
          civilization: "",
          QualityStandardJob: "",
          ToolAssessment: "",
          OtherAssessment: "",
          TotalNodalExcess: "",
          EarnedIncome: "",
        },
        dataList: [],
        from: {
          companyId: "",
          keyword: "",
          keywords: "",
          order: "sort.desc",
          daysgroupId: "",
          daysbuyId: "",
          pi: 0,
          ps: 20,
        },
        totalPage: 0,
        refreshing: false,
        loading: false,
        materialslist: [],
        finished: false,
        materialsAdd: {
          UnitPriceMaterials: 0,
          PlannedConsumption: 0,
          actualConsumption: 0,
          NodalExcess: 0,
          subtotal: 0,
        },
        materialId: "",
        compilearray: "",
      });

      // 路由初始化
      const route = useRoute();
      const router = useRouter();

      // 获取params数据信息
      reactiveParams.itemList = JSON.parse(route.params.item);
      console.log(reactiveParams.itemList, "获取数据信息");

      const onConfirm = (value) => {
        reactiveParams.result = value.name;
        reactiveParams.time = value;
        reactiveParams.showPicker = false;
        reactiveParams.materialsAdd.UnitPriceMaterials =
          reactiveParams.time.price;

        // result.value = value;
        // showPicker.value = false;
      };

      // 初始化方法
      const list = async (e) => {
        if (e == 1) {
          // 获取科队信息
          let listArray = await proxy.$Api.get(
            proxy.$consultiveManagementApi.getLaCompanysList
          );

          // 数据filter循环
          let array = listArray.data.data.filter((item) => {
            return item.id == reactiveParams.itemList.companyId;
          });
          reactiveParams.itemList.nameText = array[0].name;

          // 获取收购列表
          let listPurchase = await proxy.$Api.get(
            proxy.$laDaysGroupsApi.getlaDaysBuyspage,
            {
              order: "sort.desc",
              companyId: "",
              keyword: "",
              keywords: "",
              daysgroupId: reactiveParams.itemList.id,
              daysbuyId: "",
            }
          );
          console.log(listPurchase, "信息方法");
          reactiveParams.dataList = listPurchase.data.data.data;
        } else {
          // 获取材料的执行列表
          reactiveParams.from.daysgroupId = reactiveParams.itemList.id;
          console.log(reactiveParams.dataList);
          reactiveParams.from.daysbuyId = reactiveParams.dataList[0].id;
          let materialsList = await proxy.$Api.get(
            proxy.$laDaysGroupsApi.getlaDaysMaterialspage,
            reactiveParams.from
          );
          console.log(materialsList);
          materialsList.data.data.data.forEach((item) => {
            reactiveParams.materialslist.push(item);
          });
          reactiveParams.loading = false;
        }
      };

      const onLoad = () => {
        setTimeout(() => {
          if (reactiveParams.from.pi <= reactiveParams.totalPage) {
            reactiveParams.from.pi = reactiveParams.from.pi + 1;
            list(0);
          } else {
            reactiveParams.finished = true;
          }
        }, 500);
      };

      function onRefresh() {
        // 清空列表数据
        reactiveParams.finished = false;
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        reactiveParams.loading = true;
        reactiveParams.from.pi = 0;
        reactiveParams.totalPage = 0;
        reactiveParams.materialslist = [];
        onLoad();
      }

      // 初始化信息
      onMounted(() => {
        list(1);
      });

      // 工作量编辑
      function compile() {
        console.log("执行");
        reactiveParams.status = false;
      }

      // 工作量提交
      const affirm = async (item) => {
        let ArrayList = await proxy.$Api.put(
          proxy.$laDaysGroupsApi.PutlaDaysBuys,
          item
        );
        if (ArrayList.data.data == true) {
          Toast("提交成功！");
          reactiveParams.status = true;
          reactiveParams;
        }
      };
      // 步进器变化方法
      // 单价变化
      function unitPrice(e) {
        reactiveParams.materialsAdd.subtotal =
          Number(e) *
          (Number(reactiveParams.materialsAdd.PlannedConsumption) +
            Number(-reactiveParams.materialsAdd.actualConsumption));
      }

      // 计划消耗
      function Planned(e) {
        reactiveParams.materialsAdd.subtotal =
          Number(reactiveParams.materialsAdd.UnitPriceMaterials) *
          (Number(e) + Number(-reactiveParams.materialsAdd.actualConsumption));

        reactiveParams.materialsAdd.NodalExcess =
          e - reactiveParams.materialsAdd.actualConsumption;
      }

      // 实际消耗
      function actual(e) {
        console.log(e);
        reactiveParams.materialsAdd.subtotal =
          Number(reactiveParams.materialsAdd.UnitPriceMaterials) *
          (Number(reactiveParams.materialsAdd.PlannedConsumption) + Number(-e));
        reactiveParams.materialsAdd.NodalExcess =
          Number(reactiveParams.materialsAdd.PlannedConsumption) - e;
      }

      // 添加材料
      const addRoute = async () => {
        let list = await proxy.$Api.get(
          proxy.$laDaysGroupsApi.getLaMaterialsList,
          { companyId: reactiveParams.itemList.companyId }
        );
        reactiveParams.materialsLists = list.data.data;

        if (list.data.code == 200) {
          reactiveParams.materialsAdd.UnitPriceMaterials = 0;
          reactiveParams.materialsAdd.PlannedConsumption = 0;
          reactiveParams.materialsAdd.actualConsumption = 0;
          reactiveParams.materialsAdd.NodalExcess = 0;
          reactiveParams.materialsAdd.subtotal = 0;
          reactiveParams.result = "";
          reactiveParams.propstaus = false;
          reactiveParams.MaterialShow = true;
        }
      };

      // 材料编辑
      const EditMaterial = async (item) => {
        let list = await proxy.$Api.get(
          proxy.$laDaysGroupsApi.getLaMaterialsList,
          { companyId: reactiveParams.itemList.companyId }
        );
        reactiveParams.materialsLists = list.data.data;
        // 当条数据的储存
        reactiveParams.compilearray = item;
        reactiveParams.materialsAdd.UnitPriceMaterials = item.price;
        reactiveParams.materialsAdd.PlannedConsumption = item.planuse;
        reactiveParams.materialsAdd.actualConsumption = item.practicause;
        reactiveParams.materialsAdd.NodalExcess =
          item.planuse - item.practicause;
        reactiveParams.materialsAdd.subtotal =
          item.price * (item.planuse + -item.practicause);
        reactiveParams.result = item.materialName;
        reactiveParams.propstaus = true;
        reactiveParams.MaterialShow = true;
      };

      const deletesone = async (item) => {
        let deletesArray = await proxy.$Api.delete(
          `${proxy.$laDaysGroupsApi.PostlaDaysMaterial}?id=${item.id}`
        );
        console.log(deletesArray, "返回数组数据");
        if (deletesArray.data.code == 200) {
          Toast("删除成功！");
          onRefresh();
        } else {
          Toast("操作失败！");
        }
      };

      // 材料提交
      const materialsulit = async () => {
        if (reactiveParams.propstaus == true) {
          let materialId = "";
          if (reactiveParams.time != "") {
            if (
              reactiveParams.compilearray.materialId == reactiveParams.time.id
            ) {
              materialId = reactiveParams.compilearray.materialId;
            } else {
              materialId = reactiveParams.time.id;
            }
          } else {
            materialId = reactiveParams.compilearray.materialId;
          }
          if (reactiveParams.materialId == "" || reactiveParams.result == "") {
            Toast("请选择材料名称！");
            return;
          }
          let list = await proxy.$Api.put(
            proxy.$laDaysGroupsApi.PostlaDaysMaterial,
            {
              daysbuyId: reactiveParams.from.daysbuyId,
              materialId: materialId,
              id: reactiveParams.compilearray.id,
              planuse: reactiveParams.materialsAdd.PlannedConsumption,
              practicause: reactiveParams.materialsAdd.actualConsumption,
              price: reactiveParams.materialsAdd.UnitPriceMaterials,
            }
          );
          if (list.data.code == 200) {
            Toast("操作成功！");
            console.log("one");
            reactiveParams.MaterialShow = false;
            onRefresh();
          }
        } else {
          if (reactiveParams.result == "") {
            Toast("请选择材料名称！");
            return;
          }

          let list = await proxy.$Api.post(
            proxy.$laDaysGroupsApi.PostlaDaysMaterial,
            {
              daysbuyId: reactiveParams.from.daysbuyId,
              materialId: reactiveParams.time.id,
              planuse: reactiveParams.materialsAdd.PlannedConsumption,
              practicause: reactiveParams.materialsAdd.actualConsumption,
              price: reactiveParams.materialsAdd.UnitPriceMaterials,
            }
          );
          if (list.data.code == 200) {
            Toast("操作成功！");
            reactiveParams.MaterialShow = false;
            onRefresh();
          }
        }
      };
      // 返回
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        EditMaterial,
        onRefresh,
        addRoute,
        compile,
        affirm,
        unitPrice,
        actual,
        Planned,
        onLoad,
        onConfirm,
        deletesone,
        materialsulit,
      };
    },
  };
</script>
<style scoped>
  .styleHeader {
    font-size: 0.5rem;
    font-weight: 600;
    text-align: center;
  }
  .styleInfo {
    width: 90%;
    margin: 0 auto;
    border-radius: 0.2rem;
    background-color: #fff;
    margin-top: 0.2rem;
    padding: 0.1rem;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 0.3rem;
    color: #333;
  }
  .top {
    margin-top: 0.2rem;
  }
</style>
